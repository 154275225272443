<template>
	<div data-aos="fade-up">
		<img v-if="icon" class="mb-5 xl:w-auto w-16" :src="icon" :alt="title">
		<h3 class="text-3xl font-black leading-normal mb-1">
			{{ title }}<span class="text-theme text-base font-black transform -translate-y-4 inline-block">TM</span> Workflow
		</h3>
		<h4 class="font-bold italic mb-8 text-lg">
			<slot></slot>
		</h4>
	</div>
</template>
<script>
export default {
	name: 'WorkflowHead',
	props: [
		'title',
		'icon',
	],
}
</script>