<template>
	<div data-aos="fade-right" class="bg-secondary md:flex items-center rounded-xl p-5 md:space-x-6">
		<div>
			<img class="md:w-32 w-16 md:mb-0 mb-4" src="/images/icon-email-invite.svg" alt="">
		</div>
		<div class="w-full">
			<p class="font-bold italic leading-6">
				The employee's account on the Optiomat platform is always free of charge at all times. However, such an account can only be created on the basis of an invitation from the Optiomat account of the company's founder.
			</p>
		</div>
	</div>
</template>
<script>
export default {
	name: 'EmployeeInvitationOnly'
}
</script>