<template>
	<div class="flex items-center mb-2">
		<svg class="stroke-current text-theme mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
			<polyline points="20 6 9 17 4 12"></polyline>
		</svg>
		<span class="font-extrabold text-theme">
			{{ title }}
		</span>
	</div>
	<p class="text-base font-bold mb-8 leading-relaxed">
		<slot></slot>
	</p>
</template>
<script>
	export default {
		name: 'TitleCheck',
		props: [
			'title'
		]
	}
</script>