<template>
	<div class="relative mx-auto w-full h-full" style="max-width: 710px">
		<div style="padding: 0 0 56.25%; transform: scale(0.82) translateY(14%);" class="w-full h-0 relative z-10">
			<img :src="`/gifs/${gif}`" alt="OptioPlan Workflow" loading="lazy" class="w-full">
		</div>
		<img class="absolute top-0 left-0 right-0 bottom-0" src="/images/video-background.svg" alt="">
	</div>
</template>
<script>
export default {
	name: 'VideoTutorial',
	props: [
		'gif'
	],
	computed: {
		url() {
			return `https://www.youtube.com/embed/${this.videoId}?controls=0`
		}
	}
}
</script>