<template>
	<TheHeader hero="/images/screenshots/trustees-notifications.png">
		<template v-slot:title>
			New automated and digital way of managing ESOP trust. <span class="line relative inline-block text-theme">Reality.</span>
		</template>
		<template v-slot:description>
			Optiomat digitizes and automates most of ESOP trust administration tasks, allowing you to save time and thus keep costs at the lowest possible level.
		</template>
	</TheHeader>

	<section class="page-container md:flex md:items-start md:space-x-12 lg:pt-20 py-10 md:space-y-0 space-y-16">
		<div class="xl:w-5/12 md:w-6/12">
			<WorkflowHead title="OptioTrust" icon="/images/workflows/optiotrust-workflow.svg">
				Issue and register digital share certificates. Allot share certificates in automated regime. Record and store all data in a safe cloud-based platform.
			</WorkflowHead>

			<div data-aos="fade-up">
				<TitleCheck title="Setup" class="mb-1">
					Establish ESOP trusts using our Dutch foundation template documents.
				</TitleCheck>
			</div>

			<div data-aos="fade-up">
				<TitleCheck title="Share Certification" class="mb-1">
					Get appointed by company founders to hold option pool shares in a trust. Issue and allocate digital share certificates (depositary receipts) with a single click of a button.
				</TitleCheck>
			</div>

			<div data-aos="fade-up">
				<TitleCheck title="Shareholdings" class="mb-1">
					Access real-time data source to see recent shareholding parameters, such as face value, market value, ownership percentage, and share certificate allotments.
				</TitleCheck>
			</div>

			<div data-aos="fade-up">
				<TitleCheck title="Transactions" class="mb-1">
					Digitize the register of share certificate holders and allot share certificates notary-free, automatically and paperless. Enjoy powerful notification functionality with automated alerts and reporting when the owner of share certificates is to be changed. Rely on sophisticated ID verification of certificate holders, and the highest level of security features.
				</TitleCheck>
			</div>
		</div>
		<kinesis-container data-aos="fade-up" event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 sticky top-24 relative">
			<kinesis-element
				tag="div"
				:strength="60"
				type="translate"
				class=""
			>
				<GifTutorial gif="optiotrust.gif"/>
			</kinesis-element>
		</kinesis-container>
	</section>
</template>
<script>
	import EmployeeInvitationOnly from "../components/EmployeeInvitationOnly";
	import VideoTutorial from "../components/VideoTutorial";
	import WorkflowHead from "../components/WorkflowHead";
	import GifTutorial from "../components/GifTutorial";
	import TitleCheck from "../components/TitleCheck";
	import TheHeader from "../components/TheHeader";

	export default {
		name: 'HowItWorksTrustees',
		components: {
			EmployeeInvitationOnly,
			VideoTutorial,
			WorkflowHead,
			GifTutorial,
			TitleCheck,
			TheHeader,
		}
	}
</script>